.tooltip {
    z-index: 1;
    position: absolute !important;
    background-color: $white;

    &-arrow {
        position: relative;
        top: em(15px);
        left: 50%;
        transform: rotate(45deg);
        width: em(15px);
        height: em(15px);
        margin-left: em(-7px);
        background-color: $white;
        border-top: 3px solid color('greys', light);
        border-left: 3px solid color('greys', light);
        z-index: 1;
    }

    &-inner {
        position: relative;
        top: 7px;
        padding: em(20px, 20px);
        border: 3px solid color('greys', light);
        background-color: $snow-white;
        font-family: $font-family--primary;
        font-size: em(20px);
        font-weight: 500;
    }
}
