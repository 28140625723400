// ==========================================================================
// Helpers
// ==========================================================================

// Display

.block {
	display: block; }
.inline {
	display: inline-block; }
.inline-block {
	display: inline-block; }


// Clearfix

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.clearfix {
	@extend %clearfix;
}

// Screen reader text

.srt {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

// Images 

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}


// Align

.centered {
	float: none;
	margin-left: auto;
	margin-right: auto;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}


.white {
	color: $white;
}

.xlight-blue {
	color: $color-primary--xlighten;
}

// For pseudo-elements

%pseudos {
  display: block;
  content: ''; 
  position: absolute;
}

// Transition
%transition-base {
    transition: all 200ms;
}

// Remove default style of list

%unlist {
	padding-left: 0;
	list-style-type: none;
}


// Spacing

// Use margin to prevent addition (margin collapse)
// Exemple: <p class="spacing--xxlarge spacing--0bottom">

.spacing {

	// Modifiers

	&--base {
		margin-top: $spacing-base-vertical;
		margin-bottom: $spacing-base-vertical;
	}

	&--medium {
		margin-top: $spacing-medium-vertical;
		margin-bottom: $spacing-medium-vertical;
	}

	&--large {
		margin-top: $spacing-large-vertical;
		margin-bottom: $spacing-large-vertical;
	}

	&--xlarge {
		margin-top: $spacing-xlarge-vertical;
		margin-bottom: $spacing-xlarge-vertical;
	}

	&--xxlarge {
		margin-top: $spacing-xxlarge-vertical;
		margin-bottom: $spacing-xxlarge-vertical;
	}

	&--jumbo {
		margin-top: $spacing-jumbo-vertical;
		margin-bottom:$spacing-jumbo-vertical;
	}

	&--0top {
		margin-top: 0;
	}

	&--0bottom {
		margin-bottom: 0;
	}
	
}

// Vertical center

// 01
.vcenter {
	display: table;

	&__element {
		display: table-cell;
		vertical-align: middle;
	}
}

// 02
.vcenter-col {
	display: inline-block;
    vertical-align: middle;
    float: none !important;
    // Space fix
    margin-right: -4px;
}


// 03
.vertical-flex {
  display: flex;
  flex-direction: row;
}

.vertical-flex > [class^="col-"],
.vertical-flex > [class*=" col-"] {
  display: flex;
  align-items: center;
  justify-content: center;
}