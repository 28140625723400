/* ==========================================================================
   Optionals
   ========================================================================== */
   
/**
 * Optionals are dynamic field that are optional in a form.
 * They are hidden my default
 *
 */
 
.optional-field {
    display: none;
}

.optional-is-visible {
    .optional-field {
        display: block;
    }
}