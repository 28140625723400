.select-hidden {
    display: none;
    visibility: hidden;
    padding-right: 10px;
}

.select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 16px;
    color: $select-font-color;
    width: $select-width;
}

.select-styled {
    position: relative;
    background-color: $select-background;
    border-bottom: 2px solid $gray-xlight;
    padding-left: 5px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 85px;
    text-align: left;
    cursor: default;

    @extend %transition-base;

    &:active, &.active {
    }
}

.select-options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: $select-background;
    //border-left: 2px solid $select-border-color;
    //border-right: 2px solid $select-border-color;
    text-align: left;

    max-height: 360px;
    overflow-y: scroll;

    li {
        margin: 0;
        padding-top: 25px;
        padding-bottom: 25px;
        text-indent: 15px;

        border-bottom: 2px solid $select-border-color;

        &[rel="hide"] {
            display: none;
        }
    }
}
