.adisq-mfb-custom {
	.mfp-container {
		cursor: default;
	}
	.mfp-image-holder .mfp-close {
		cursor: pointer;
	}
	.mfp-counter {
		display: none;
	}
	.mfp-title {
		font-size: 14px;
		color: $white;
		font-family: $font-family--primary;
		font-weight: $font-weight--light;
		padding-right: 0;
		margin-top: 10px;
		text-transform: uppercase;
	}
	.mfp-arrow {
		&:before {
			border: none;
		}
		@extend %transition-base;
	}

	.mfp-arrow-right:after {
		border-left: 17px solid $color-primary--xlighten;
	}
	.mfp-arrow-left:after {
		border-right: 17px solid $color-primary--xlighten;
	}

}