.slider {

}

.slick-arrow {
    position: absolute;
    top: 0;
    display: table;
    height: 100%;
    padding-left: $gutter-width/2;
    padding-right: $gutter-width/2;
    color: $white;
    font-size: 2em;
    outline: 0;
    cursor: pointer;
    z-index: 5;

    i {
        display: table-cell;
        vertical-align: middle;
    }
}

.slick-prev {
    left: 0;
}

.slick-next {
    right: 0;
}

.slick-dots {
    position: absolute;
    bottom: $gutter-width/2;
    width: 100%;
    text-align: center;

    li + li {
        margin-left: $gutter-width/3;
    }

    li {
        display: inline-block;

        &.slick-active {
            button {
                background-color: $color-primary;
            }
        }
    }

    button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 18px;
        height: 18px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        outline: none;
        background-color: rgba($white, 0.5);
        border: 2px solid $white;
        border-radius: 50%;
    }
}
