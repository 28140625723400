// ========================================================
// listing
// ========================================================

.listing {

	&__row {
		@media only screen and (min-width: $small-width) {
			margin-top: em(30px);
		}
	}

	&__item {
		position: relative;
		padding-right: $spacing-xxlarge-horizontal;
		padding-bottom: $spacing-medium-vertical;
		margin-top: $spacing-medium-vertical;

		border-bottom: 2px solid $color-primary;

		width: 100%;

		@media only screen and (min-width: $small-width) {
			margin-top: 0;
			margin-bottom: $spacing-large-vertical;;
		}



		// Modifiers

		&--dark-border {
			border-color: $gray-base;
		}
		&--light {
			border-color: $color-primary--xlighten;
		}
		&--grey-light {
			border-color: $gray-xlight;
		}
		&--no-icon {
			padding-right: 0;
		}

	}

	&__icon {
		position: absolute;
		right: 0; top: 5px;
	}

	&__group {
		font-size: em(16px);
		color: $color-primary;
		margin-bottom: 0;
		margin-top: em(30px, 16px);
		@media only screen and (min-width: $small-width) {
			font-size: em(18px);
			margin-bottom: em(25px, 16px);
			margin-top: 0;
		}
	}

	&__title {
		font-family: $font-family--primary;
		font-weight: $font-weight--bold;
		font-size: em(16px);
		text-transform: uppercase;
	}

	&__subtitle {
		margin-bottom: em(15px, 18px);
	}

    .adisq-icon {
        width: 2.5rem;
        height: 2.5rem;

        &:before {
            font-size: 1rem;
            line-height: 2.5rem;
        }
    }

}


.partners-list {
	text-align: center;
	&__item {
		display: inline-block;
		margin-right: 10px;
	}
}
