.scrollspy {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 30px;
    z-index: 45;
	&__item {
		position: relative;
		padding-right: 35px;
		display: inline-block;

		&:last-of-type {
			padding-right: 0;
			&:after {
				display: none;
			}
		}

		&.active {
			a {
				background: $color-primary;
			}
		}

		a {
			display: block;
			width: 14px;
			height: 14px;
			border-radius: 7px;
			border: 2px solid $color-primary;
        	@extend %transition-base;

			@media screen and (min-width: $nohover-width) {
				&:hover {
		            span {
		                opacity: 1;
		                visibility: visible;
		                transform: scale3d(1,1,1) translate3d(-40px,0,0);
		            }
	        	}
			}
		}

		&:after {
			content: "";
			position: absolute;
			width: 40px;
			left: 14px;
			height: 2px;
			background-color: $color-primary ;
			top: 50%;
	    	transform: translateY(-50%);
	    	z-index: -1;
		}
	}



	span {
	    @extend %transition-base;
        position: absolute;
        top: 30px;
        left: 0;
        display: inline-block;
        padding: 8px 15px;
        opacity: 0;
        visibility: hidden;
        transform: scale3d(0.8,0.8,0.8) translate3d(0, -10px,0);
        border: 2px solid $color-primary;
		border-radius: $br--round;

		font-family: $font-family--primary;
		font-weight: $font-weight--bold;
		font-size: em(14px);
		color: $color-primary;
		text-align: center;
		text-transform: uppercase;
		cursor: default;

	}

	// Modifiers
	&--light {
		a {
			border-color: $white;
		}
		li {
			&:after {
				background-color: $white;
			}
		}

		span {
			color: $white;
			border-color: $white;
		}

        .active {
            a {
                background: $white;
            }
        }
    }
}
