// ========================================================
// Site footer
// ========================================================

.sf {
	padding-top: 35px;
	padding-bottom: 35px;
	background-color: $gray-xlight;

	font-family: $font-family--primary;
	color: $color-primary;
	position: relative;

	ul {
		display: inline-block;
	}

	&__nav {
		//margin-bottom: em(30px);

		ul {
			display: inline-block;
			vertical-align: top;
			margin-bottom: 20px;
			margin-right: 20px;
		}
	}


	&__icon {
		display: inline-block;
		padding-right: $spacing-medium-horizontal;
        a {
            text-transform: uppercase;
            font-size: .75em;
            i {
                font-size: 1.25rem;
            }
        }
	}

	&__cr {

		font-family: $font-family--secondary;
		font-size: em(12px);
		color: $gray-base;
		text-align: center;

		@media only screen and (min-width: $medium-width) {
			text-align: left;
            padding-top: 10px;
		}
	}

	&__logo {
		text-align: center;
		margin-bottom: 0;
		margin-top: 20px;

		@media only screen and (min-width: $medium-width) {
			margin-top: 0;
            text-align: right;
		}

		a {
			display: inline-block;
			line-height: 20px;
			margin-right: 2%;
            @media only screen and (min-width: $large-width) {
                margin-right: 5%;
            }
		}

		img {
			@include grayscale();
			width: 110px;
			vertical-align: middle;
		}
	}

	.globalia {
        display: block;
		font-family: $font-family--primary;
		font-size: 10px;
		text-transform: uppercase;
		color: gray;
		margin-top: 25px;
        opacity: .5;

		img {
			padding-left: 5px;
            width: 75px;
		}

		&:hover {
			@media only screen and (min-width: $nohover-width) {
				text-decoration: none;
			}

		}
	}
}
