// ========================================================
// Site jumbotron
// ========================================================

.jumbotron {
	padding: 60px 0;
	color: $white;
	text-align: center;
	@media only screen and (min-width: $small-width) {
		padding: 100px 0;
	}

	@media only screen and (min-width: $medium-width) {
		padding: 100px 0 200px;
	}

	&__bg {
		background-image: url("/images/bg-jumbotron.jpg"); // Set fallback here
		background-repeat: no-repeat;

	}

	&__cta {
		margin-top: $spacing-xlarge-vertical;
	}


	// Page background-image controller

	&--association {
		background-image: url("/images/bg-jumbo-association.jpg");
		background-size: cover;
	}

	&--gala {
		background-image: url("/images/bg-jumbotron.jpg");
		background-size: cover;
	}
}
