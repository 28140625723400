.section {
    position: relative;

    &__inner {
        position: relative;
        padding: 6rem em($grid-gutter-width/2);
        font-size: 1em;
        text-align: left;
        z-index: 41;
    }

    &__cover {
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        overflow: hidden;
        z-index:40;

        &:before {
            content:'';
            display: block;
            width: 100%;
        }

        img {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: auto;
            transform: translate3d(-50%,-50%,0);
            min-width: 100vw;
            min-height: 100%;
            max-width: unset;
        }
    }

    &__title {
        font: 400 40px/1.1 $font-family--primary;
        margin: 0 0 2rem;
        @include mq(sm){
            font-size: 60px;
        }
    }
    &__subtitle {
        font-size: 2rem;
        margin-bottom: 1rem;
        text-transform: uppercase;
    }

    &__cta {
        margin-top: 3rem;
    }

    &--tabs {
        .section__inner {
            padding-top: 4rem;
            padding-bottom: 6rem;
        }
    }

    &--news-top {
        .section__inner {
            padding-top: 3rem;
            padding-bottom: 3rem;
            z-index: 42;
        }

        .row > li:nth-child(odd) {
            clear: left;
        }
    }

    &--news-list {
        background: #f1f1f1;

        .section__inner {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }

    &--news-entry {
        .typography {
            margin-top: 2rem;
        }
    }

    &--membership {
        background-image: url("/images/bg-forces.jpg");
    }

    &--directory {
        @extend .section--tabs;

        h1 {
            margin-bottom: 3rem;
        }
    }

    &--process {
        .section__cover img {
            bottom: 0;
            top: auto;
            left: auto;
            right: 10px;
            transform: translate3d(0,0,0);
            max-width: unset;
            opacity: .1;
            height: 90%;
            min-height: 90%;
            min-width: 0;
            @include mq(md){
                transform: translate3d(0,0,0);
                opacity: 1;
            }
            @include mq(lg){
                right: calc((100vw - #{$container-width}) / 2);
            }
        }
        .section__inner {
            @include mq(md){
                padding-right: calc(5* #{$container-width} / 12);
            }

        }
    }

    &--rencontres-cta {
        position: relative;
        color: $white;

        h2,
        .news-card__time {
            color: $white;
        }

        .btn {
            margin-bottom: 1em;
        }
    }

    &--rencontres {
        position: relative;
    }

    &--prix {
        .card__info {
            display: block;
        }
    }

    &--formations-training {
        background: $color-primary;
        color: $white;

        h2,
        h3 {
            color: $white;
        }

        .section__subtitle {
            margin-top: 2rem;
        }

        .frame {
            margin-top: 1rem;
            border: 3px solid $white;
            padding: 1.5rem;

            h3 {
                margin-bottom: 1.5rem;
            }

            .typography {
                font-size: em(14px);
            }

            @include mq(sm) {
                margin-top: 0;
            }
        }

        .formations__content {
            margin-top: 2rem;
            @extend .typography;
        }

        .section__title {
            margin-bottom: 2rem;
        }

        .cta-card__content {
            p {
                @media only screen and (min-width: $xsmall-width) {
                    font-size: .875em;
                }
            }
        }
    }

    &--partners-training {
        position: relative;
        background: $color-primary;
        color: $white;
    }

    &--faq {
        .cta-card + h2 {
            margin-top: 3rem;
        }
    }

    &--documents {
        @extend .section--tabs;

        .wysiwyg-container {
            margin-bottom: 2rem;
        }

        .h4 {
            text-transform: uppercase;
            margin-bottom: .5rem;
        }
    }

    &--calendar {
        z-index: 1;

        .section__inner {
            padding-bottom: 1rem;

            @include mq(sm) {
                padding-bottom: 6rem;
            }
        }
    }

    &--events {
        position: relative;
        z-index: 0;

        .section__title {
            text-align: center;
        }

        .section__inner {
            padding-top: 0;
        }

        .list--events {
            list-style: none;
        }

        .cta-card__date {
            font-size: 1.5em;
            font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
            text-transform: uppercase;
        }

        .section__cta {
            margin-top: 0;
            margin-bottom: 5rem;
        }
    }

    &--darkblue {
        background-color: $daintree;
    }

    &--grey {
        background-color: #f3f3f3;
    }

    &--statement {
        background-image: url("/images/bg-statement.jpg");
    }

    &--census {
		background-image: url("/dist/images/bg-blue-guitare.jpg");
	}

    &--publicities {
        background-image: url("/dist/images/bg_process-publicites.jpg");
    }

    &--publicities,
    &--statement,
    &--census {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 100%;
	}

    &--reglementation {
        .accordeon {
            padding-bottom: em(40px);
        }
    }

    &--promo {
        background: #3e1e42 url("/dist/images/promo-pattern.png") repeat;

        .section__title,
        .typography {
            color: $white;
        }

        [class^='col-'] + [class^='col-'] {
            margin-top: $grid-gutter-width;

            @include mq(md) {
                margin-top: 0;
            }
        }
    }
}

.form--contact {
    margin-top: 30px;
}

.sticky-promo {
    position: fixed;
    bottom: -1px;
    left: 0;
    right: 0;
    z-index: 98;
    padding-top: $grid-gutter-width / 2;
    padding-bottom: $grid-gutter-width / 2;
    background: #3e1e42 url("/dist/images/promo-pattern.png") repeat;
    transition: transform .3s;

    &.is-hidden {
        transform: translateY(100%);
    }

    .container {
        display: flex;
        flex-direction: column;
    }

    .btn-close {
        margin-bottom: $grid-gutter-width / 2;
    }

    .btn {
        order: 3;
        margin-top: $grid-gutter-width / 2;
        padding-top: em(14px);
        padding-bottom: em(14px);
    }

    .typography {
        order: 2;
        color: $white;
    }

    @include mq(xs) {
        .container {
            display: block;
        }

        .typography {
            text-align: right;
        }

        .btn {
            float: right;
            margin-top: 0;
            margin-left: em(20px);
        }
    }

    @include mq(sm) {
        .btn-close {
            float: left;
            margin-bottom: 0;
        }

        .typography {
            padding-top: .875rem;
        }
    }
}
