meta.foundation-mq-small {
  font-family: "/only screen/";
  width: 0em; }

meta.foundation-mq-medium {
  font-family: "/only screen and (min-width: #{$medium-width})/";
  width: $medium-width; }

meta.foundation-mq-large {
  font-family: "/only screen and (min-width: #{$large-width})/";
  width: $large-width; }

meta.foundation-mq-xlarge {
  font-family: "/only screen and (min-width:90.063em)/";
  width: 90.063em; }

meta.foundation-mq-xxlarge {
  font-family: "/only screen and (min-width:120.063em)/";
  width: 120.063em; }

  meta.foundation-data-attribute-namespace {
  font-family: false; }