@include card('.news-card', 0, 0, 0);

.news-card {
    figcaption,
    &__category {
        display: inline-block;
        background: $color-primary;
        color: $white;
        padding: .75rem 1rem;
        text-transform: uppercase;
        font: 400 .875em/1 $font-family--primary;
    }
    &__figure {
        display: block;
    }
    figcaption {
        position: absolute;
        top: 0;
        left: 0;
    }
    &__time {
        color: $color-primary;
        @extend .time;
    }
    &__title {
        font: 400 1.25em/1.3 $font-family--primary;
        &:hover {
            text-decoration: none;
        }
    }
    &__content {
        margin-top: .75em;
    }
    &__cta {
        display: block;
        color: $color-primary;
        margin-top: .75em;
        &:before {
            content: "\f061";
            font-family: $font-family--icon;
            margin-right: .75em;
            transition: margin .375s cubic-bezier(0, 0.5, 0.5, 1);
        }
        &:hover {
            text-decoration: none;
            &:before {
                content: "\f061";
                font-family: $font-family--icon;
                margin-left: .25em;
                margin-right: .5em;
            }
        }
    }
    li.col-sm-6 > & {
        padding-bottom: 2em;
    }
    .section--news-list & {
        padding-bottom: 3em;
        padding-top: 2em;
        border-bottom: 1px solid #cdcdcd;
    }
}
