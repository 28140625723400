// ==========================================================================
// Functions
// ==========================================================================

// Calculate em values

@function em($values, $context: $base__font-size) {
  $em: ();

  @each $value in $values {
    @if $value == 0 or $value == auto {
      $em: append($em, $value);
    }
    @else {
      $em: append($em, ( ( $value/ $context ) * 1em ));
    }
  }

  @return $em;
}
