.privilege {
    &__category {
        margin-top: 60px;

        img {
            margin-right: 40px;
        }

        [href*=".pdf"]:not(.btn):not(.adisq-icon) {
            padding-left: 0;

            &:before {
                content: none;
            }
        }
    }

    &__title {
        @extend .h3;
        margin-bottom: 20px;
    }
}
