// ==========================================================================
// Calendar
// ==========================================================================

.calendar {
    position: relative;

    &__title {
        position: relative;
        min-height: em(52px);
        margin-bottom: em($grid-gutter-width);
        padding: 0 em(62px);
        font-family: $font-family--primary;
        color: $color--primary;
        text-align: center;

        [data-calendar-nav="today"] {
            display: inline-block;
            font-size: em(40px);
            line-height: em(52px, 40px);

            span {
                display: inline-block;

                & + span {
                    margin-left: em(5px);
                }
            }

            @include mq(xs) {
                font-size: em(60px);
                line-height: em(52px, 60px);
            }
        }

        button {
            outline: 0;
        }
    }

    &__prev,
    &__next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &__prev {
        left: 0;
        margin-right: em(30px);
    }

    &__next {
        right: 0;
        margin-left: em(30px);
    }

    &__legend {
        display: none;
        list-style: none;
        margin-top: em(- $grid-gutter-width*1.5);
        margin-bottom: em($grid-gutter-width*2);
        text-align: center;

        &.is-open {
            display: block;
        }

        li {
            font-family: $font-family--primary;
            font-size: em(14px);
            padding: em(5px, 14px);
            text-transform: uppercase;
            vertical-align: middle;
            text-align: left;
            cursor: pointer;
        }

        .tag {
            position: relative;
            top: em(4px);
            width: 1.5rem;
            height: 1.5rem;
            margin-right: em($grid-gutter-width/2, 18px);
            vertical-align: sub;

            &:before {
                content: '';
                position: absolute;
                top: 7px;
                left: 9px;
                width: 5px;
                height: 8px;
                border-right: 2px solid $snow-white;
                border-bottom: 2px solid $snow-white;
                transform: rotate(25deg) scale(0);
                transition: transform $transition--fast;
                color: $snow-white;
            }

            &.is-active:before {
                transform: rotate(40deg) scale(1);
            }
        }

        @include mq(sm) {
            display: block;
            margin-top: 0;

            li {
                display: inline-block;
                padding: 0;
                margin-bottom: em($grid-gutter-width/4, 18px);

                & + li {
                    margin-left: em($grid-gutter-width, 18px);
                }
            }
        }
    }

    &__filters {
        position: relative;
        margin-bottom: em($grid-gutter-width*2);
        border-bottom: 2px solid $gray-xlight;
        padding-left: 5px;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 85px;

        .adisq-icon-wrapper {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }

        @include mq(sm) {
            display: none;
        }
    }

    &__loader {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: transparentize($darth-vader, .6);

        .spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 3.125rem;
            height: 2.5rem;
            text-align: center;
            font-size: .625em;

            > div {
                display: inline-block;
                height: 100%;
                width: 6px;
                background-color: $snow-white;
                animation: sk-stretchdelay 1.2s infinite ease-in-out;
            }

            .rect2 {
                animation-delay: -1.1s;
            }

            .rect3 {
                animation-delay: -1.0s;
            }

            .rect4 {
                animation-delay: -0.9s;
            }

            .rect5 {
                animation-delay: -0.8s;
            }
        }

        @include mq(xs) {
            display: block;
        }
    }
}

.media-ctas {
    text-align: center;

    .btn {
        margin: 0 1em 2em 1em;
    }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
    }  20% {
        transform: scaleY(1.0);
    }
}


// ==========================================================================
// Calendar // Month table
// ==========================================================================

$size : percentage(1/7);

.cal-context,
.calendar--home {
    display: none;

    @include mq(sm) {
        display: block;
    }
}

@include mq(sm) {
    [class*="cal-cell"] {
        width: $size;
    }
}

.cal-row {
    &-fluid {
        @extend %clearfix;
    }

    &-head {
        display: none;
        background-color: $color--primary;
    }

    @include mq(sm) {
        &-head {
            display: block;

            [class*="cal-cell"] {
                float: left;
                padding: em(20px, 18px) 0;
                font-family: $font-family--primary;
                font-size: em(18px);
                color: $snow-white;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }
}

.cal-month {
    &-box {
        border-left: 1px solid color('greys', 'xlight');
        border-right: 1px solid color('greys', 'xlight');
        border-bottom: 1px solid color('greys', 'xlight');

        [class*="cal-cell"] {
            position: relative;
            border-bottom: 1px solid color('greys', 'xlight');

            &:last-child {
                border-right: 0;
            }
        }
    }

    &-day {
        position: relative;
        min-height: em(125px);

        span {
            display: block;
            width: 100%;
            padding: em(10px, 21px);
            background-color: $gray-base;
            font-family: $font-family--primary;
            font-size: em(21px);
            color: $snow-white;
        }

        &.cal-day-outmonth {
            span {
                background-color: color('greys', 'xlight');
            }
        }

        .events-list {
            padding: em(20px 10px);

            a {
                position: relative;
                @extend .tag;
            }
        }
    }

    @include mq(sm) {
        &-box {
            display: table;
            width: 100%;

            .cal-row-fluid {
                display: table-row;

                &:not(:first-child) {
                    border-top: 1px solid color('greys', 'xlight');
                }
            }

            [class*="cal-cell"] {
                display: table-cell;
                border-right: 1px solid color('greys', 'xlight');
                border-bottom: 0;

                &:last-child {
                    width: calc( #{$size} + 4px );
                }
            }
        }
    }
}

#cal-day-tick {
    display: none !important; // overwrite javascript
}

// ==========================================================================
// Calendar // Event tags
// ==========================================================================

.tag {
	display: inline-block;
	width: 1.25rem;
	height: 1.25rem;
	border-radius: 100%;
    margin-right: .625rem;

    &--launch {
		background-color: #ea5647;
	}

    &--premiere {
		background-color: #f4a60e;
	}

    &--training {
		background-color: #9a4db6;
	}

	&--press-releases {
		background-color: #0395df;
	}

	&--event {
		background-color: #2ab86a;
	}

    &--festivals {
		background-color: #54cdd1;
	}

    &--autres {
		background-color: $gray-base;
	}
}
