img {
	max-width: 100%;
	height: auto;
}

.svg {
	width: auto;
}

.site-wrapper {
	position: relative;
	overflow: hidden;
	width: 100%;
    z-index:40;
}
