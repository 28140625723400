@include card('.cta-card', false, false, 0, false);

.cta-card {
    position: relative;
    padding: em(25px) 0;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        height: 2px;
        background-color: $gray-xlight;
    }

    &__category {
        display: inline-block;
        background: $color-primary;
        color: $white;
        padding: .75rem 1rem;
        margin-bottom: 1em;
        text-transform: uppercase;
        font: 400 .875em/1 $font-family--primary;
    }

    &__date {
        color: $color-primary;
        @extend .time;
    }

    &__content {
        position: relative;
        padding-right: em(72px, 20px);
        font-family: $font-family--primary;
        font-size: em(20px);
    }

    &__icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        .adisq-icon {
            @extend .fa;
            @extend .fa-arrow-right;
            width: 2.2rem;
            height: 2.2rem;

            &:before {
                line-height: 1.75em;
            }
        }
    }

    &--inverse {
        &:after {
            background-color: #3ebeff;
        }

        .cta-card__icon {
            .adisq-icon {
                &:hover {
    				@media only screen and (min-width: $nohover-width) {
    					background: transparent;
    					color: $white;
    					box-shadow: 0 0 0 4px transparentize(#3ebeff, .5);
    				}
    			}
            }
        }
    }

    &[data-expander] {
        .cta-card__content {
            cursor: pointer;
        }

        .cta-card__icon .adisq-icon {
            @extend .fa-plus;

            &:before {
                line-height: 1.85em;
            }
        }
    }

    &.js-expanded {
        .cta-card__icon .adisq-icon {
            @extend .fa-minus;
        }
    }

    &__expand {
        @extend .expander__content;
        font-size: em(17px);
    }

    .expander__wrapper {
        padding-top: em(25px, 17px);
    }
}
