.process {
	position: relative;
    .section__inner {
        padding-top: 9rem;
    }
	p {
		line-height: 2;
	}
}

.landmark {
	h2 {
		margin-bottom: 25px;
	}

	&__img {
		margin-left: -190px;
	    margin-top: -74px;
	}
}

.census {
	color: $white;
	text-align: center;

	h2 {
		color: $white;
		margin-bottom: 30px;
	}
}

.reglementation {
	.intro {
		margin-bottom: 25px;
	}
	h3 {
		margin-bottom: 20px;
	}
	.rules {
		margin-bottom: 25px
	}

	&__img {
		margin-right: -80px;
		margin-top: -70px;
	}
}

.publicities {
	h2, .intro {
		text-align: center;
	}

	.intro {
		color: $white;
		margin-bottom: 40px;
	}
	.section-title {
		width: 100%;
		color: $white;
		margin-bottom: 20px;
		margin-top: 20px;


		@media screen and (min-width: $small-width) {
			text-align: center;
			margin-bottom: 40px;
			margin-top: 40px;
		}
	}
	.documents {
		text-align: center;
		.btn {
			//margin-right: 40px;
			margin-bottom: 30px;
			margin-left: 10px;
			margin-right: 10px;
		}

		@media screen and (min-width: $small-width) {
			margin-right: 20px;
			margin-left: 20px;
		}
	}
}

.documents {
	padding-top: 30px;
	padding-bottom: 30px;

	.btn {
		margin-bottom: 20px;
	}
}
