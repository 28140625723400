.expander {
    &__trigger {
        cursor: pointer;
    }

    &__wrapper {
        opacity: 0;
        transform: scale(0.9);
    }

    &__content {
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        display: block;
        height: 0;
        overflow: hidden;
        transition: height $transition--fast linear;
    }
}

[data-expander] {
    position: static;
    transition: padding-bottom $transition--fast linear;

    &.js-expanded {
        .expander__wrapper {
            transition: all $transition--fast linear;
            transform: scale(1);
            opacity: 1;
        }
    }
}
