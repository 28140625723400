.accordion {
	position: relative;


    &__trigger {
    position: relative;
    padding-top: 35px;
    padding-bottom: 35px;
    padding-right: 40px;
    border-bottom: 2px solid $gray-xlight;
    transition: border-bottom-color 0.6s;

        &.is-expanded {
          border-bottom-color: transparent;
            .adisq-icon {
                &:before {
                    content: "\f068 ";
                }
            }
        }
        h3 {
          margin-bottom: 0;
        }
    }

    .adisq-icon-wrap {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        .adisq-icon {
            box-shadow: inset 0 0 0 2px $color-primary;
            color: $color-primary;
        }
    }

    &__item {
        height:auto;
        overflow:hidden;
        max-height:50em;
        transition: max-height 0.6s;
        &.is-collapsed {
            max-height: 0;
        }
    }

    &__content {
        margin-bottom: 25px;
        line-height: 2;
        p {
          margin-bottom: 10px;
          @media only screen and (min-width: $xsmall-width) {
            margin-bottom: 15px;
          }
        }
    }

}

.animateIn {
     animation: accordionIn 0.65s normal ease-in-out both 1;
}
.animateOut {
     animation: accordionOut 0.75s alternate ease-in-out both 1;
}
@keyframes accordionIn {
  0% {
    opacity: 0;
    transform:scale(0.9);
  }
  100% {
    opacity:1;
    transform:scale(1);
  }
}

@keyframes accordionOut {
    0% {
       opacity: 1;
       transform:scale(1);
     }
     100% {
          opacity:0;
           transform:scale(0.9) rotateX(-60deg);
       }
}
