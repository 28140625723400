// ========================================================
// Gallery
// ========================================================

// Remove margin top of first row items
.gallery .gallery__item:first-child,
.gallery .gallery__item:first-child + .gallery__item {
	margin-top: 0;
}

@media only screen and (min-width: $small-width) {
	//gallery have 4 items in a row
	.gallery .gallery__item:nth-child(-n+4) {
		margin-top: 0;
	}
}

@media only screen and (min-width: $medium-width) {

	//gallery--lg have 6 items in a row
	.gallery--lg .gallery__item:nth-child(-n+6) {
		margin-top: 0;
	}
}

.gallery {
	&__title {
		font-size: 14px;
		margin-top: 20px;
	}
	&__item {
		margin-top: 20px;

		@media only screen and (min-width: $small-width) {
			margin-top: 40px;
		}
	}
	&__img-link {
		position: relative;
		display: inline-block;
		outline: 0;
		width: 100%;
	}
	&__img {
		@extend .img-responsive;
		width: 100%;
	}
	&__overlay {
		position: absolute;
		top: 0; bottom: 0;
		left: 0; right: 0;
		background-color: rgba(0, 29, 46, 0.4);

		.btn {
			position: absolute;
			left: 50%; top: 50%;
			transform: translate(-50%, -50%);
		}
	}
}