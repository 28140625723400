@font-face {
	font-family: 'Libre Baskerville';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/Libre_Baskerville-normal-400.woff) format('woff');
}

@font-face {
	font-family: 'Libre Baskerville';
	font-style: normal;
	font-weight: 700;
	src: url(../fonts/Libre_Baskerville-normal-700.woff) format('woff');
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/Montserrat-normal-400.woff) format('woff');
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: url(../fonts/Montserrat-normal-700.woff) format('woff');
}