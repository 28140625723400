// ==========================================================================
// Global Config
// ==========================================================================

// Font Stacks

$font-family--primary        : 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family--secondary      : 'Libre Baskerville', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family--icon           : 'fontAwesome';
$font-path                   : '/fonts';


// Font Weights

$font-weight--thin       : 100;
$font-weight--light      : 300;
$font-weight--medium     : 400;
$font-weight--bold       : 700;
$font-weight--ultra-bold : 900;

// Descriptive Base Colors

$white     : #fff;
$black     : #000;

$lochmara         : #0078bd;
$lochmara--light  : #008ada;
$lochmara--xlight : #36b6ff;
$daintree         : #001D2E;

// Color Usage

$color-primary            : $lochmara;
$color-primary--lighten   : $lochmara--light;
$color-primary--xlighten  : $lochmara--xlight;
$color-primary--darken    : darken($color-primary, 5%);
$color-secondary          : red;

$color-warning   : red;
$color-sucess    : blue;
$color-info      : blue;

$gray-xxlight    : #eeeeee;
$gray-xlight     : #ece7e2;
$gray-light      : #ccc;

$gray-base       : #4c4c4c;

$gray-dark       : #323132;
$gray-xdark      : #3c3c3c;


// Text

$base__font-size   : 16px;
$base__line        : 1.8;
$base__font-color  : $gray-base;
$letter-space      : 1px;


// Border radius

$br--default    : .25em;
$br--round      : 50px;


// Select

$select-font-color: $base__font-color;
$select-background: $white;
$select-border-color: $gray-xlight;
$select-color-hover: $color-primary;
$select-width: 400px;

// Grid

$grid-columns: 12;
$grid-gutter-width: 30px;


// Media queries
$nohover-width   : 1025px;

$container-width   : 1240px;
$large-width       : 1200px;
$medium-width      : 992px;
$small-width       : 768px;
$xsmall-width      : 480px;
$xxsmall-width     : 320px;


// Components

$spacing-base-vertical    : 10px;
$spacing-medium-vertical  : $spacing-base-vertical * 2; // 20px
$spacing-large-vertical   : $spacing-base-vertical * 3; // 30px
$spacing-xlarge-vertical  : $spacing-base-vertical * 4; // 40px
$spacing-xxlarge-vertical : $spacing-base-vertical * 5; // 50px
$spacing-jumbo-vertical   : $spacing-base-vertical * 8; // 80px

$spacing-base-horizontal    : 10px;
$spacing-medium-horizontal  : $spacing-base-horizontal * 2; // 20px
$spacing-large-horizontal   : $spacing-base-horizontal * 3; // 30px
$spacing-xlarge-horizontal  : $spacing-base-horizontal * 4; // 40px
$spacing-xxlarge-horizontal : $spacing-base-horizontal * 5; // 50px
$spacing-jumbo-horizontal   : $spacing-base-horizontal * 8; // 80px
