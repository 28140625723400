// ==========================================================================
// Typography
// ==========================================================================
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: $ff--secondary;
    font-weight: 400;
}

.h1 {
    font-size: em(26px);
    color: $color-primary;

    @media only screen and (min-width: $xsmall-width) {
        font-size: em(60px);
    }
}

.h2 {
    font-size: em(26px);
    color: $color-primary;

    @media only screen and (min-width: $xsmall-width) {
        font-size: em(60px);
    }
}

.h3 {
    font-family: $ff--secondary;
    font-weight: $font-weight--bold;
    font-size: em(16px);
    text-transform: uppercase;
    color: $color-primary;

    @media only screen and (min-width: $xsmall-width) {
        font-size: em(18px);
    }
}

.h4 {
    font-family: $ff--secondary;
    font-weight: $font-weight--bold;
    font-size: em(16px);

    @media only screen and (min-width: $xsmall-width) {
        font-size: em(18px);
    }
}

.h5 {
    font-family: $ff--secondary;
    font-weight: 400;
    font-size: em(16px);
    color: $color-primary;

    @media only screen and (min-width: $xsmall-width) {
        font-size: em(18px);
    }
}

.p {
    font-size: em(14px);

    @media only screen and (min-width: $xsmall-width) {
        font-size: em($base__font-size);
    }

    &.small {
        font-size: em(13px);
    }
}

.strong {
    font-weight: $font-weight--bold;
}

.link {
    color: inherit;
    text-decoration: none;

    @media only screen and (min-width: $nohover-width) {
        &:hover {
            text-decoration: underline;
        }
    }

    &--overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
    }
}

.unordored-list {
    list-style-type: none;

    li {
        margin-bottom: $gutter-width/2;

        &:before {
            content: "\f061";
            font-family: $font-family--icon;
            color: $color-primary;
            margin-right: $gutter-width/2;
        }
    }
}

.section-title {
    margin-bottom: $margin/2;
    line-height: 1.1;
}

.section-title + .typography {
    margin-bottom: 3rem;
}

.error {
    color: #e74c3c;
}

.success {
    color: #27ae60;
}

.typography {
    h1, .h1 { @extend .h1; margin-bottom: $margin/2 }
    h2, .h2 { @extend .h2; margin-bottom: $margin/2 }
    h3, .h3 { @extend .h3; margin-bottom: $margin/2 }
    h4, .h4 { @extend .h4; margin-bottom: $margin/2 }
    h5, .h5 { @extend .h5; margin-bottom: $margin/2 }
    h6, .h6 { @extend .h6; margin-bottom: $margin/2 }
    p:not([class]) {  @extend .p; margin-bottom: $margin; line-height: 2}
    p[class] { @extend .p; }
    a { @extend .link; }
    ul { @extend .unordored-list; margin: $margin 0; }
    strong, b { @extend .strong; }
}
