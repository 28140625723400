// ========================================================
// Card
// ========================================================

.card {
    position: relative;
    display: table;
	padding: 30px 35px;
	border: 2px solid $color-primary;
	line-height: 1.8;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	margin-bottom: em(30px);

    &:after {
        content: '';
        display: table;
        clear: both;
    }

	&__icon {
        display: table-cell;
        width: 30%;
        padding-right: .5em;
		font-size: 3em;
		color: $color-primary;
        line-height: 1;
		text-align: center;

        .icon-03 {
            font-size: .75em;
        }

        .icon-05 {
            font-size: .85em;
        }

        .icon-08 {
            font-size: .85em;
        }

		@media only screen and (min-width: $xsmall-width) {
			font-size: 4.5em;
		}
	}

	&__title {
		font-size: em(16px);
		font-weight: $font-weight--bold;
		color: $color-primary;
		margin-bottom: em(15px, 16px);
	}

	&__info {
        display: table-cell;
        vertical-align: middle;
        list-style: none;
		font-size: em(14px);

		&--win {
			position: relative;
			color: $color-primary--xlighten;
			font-weight: $font-weight--bold;

			&.first {
				padding-left: 20px;

				&:before {
					position: absolute;
					left: 0;

					content: "\f091";
					font-family: $font-family--icon;
					font-weight: $font-weight--light;
				}
			}
		}
	}

    [data-expander] & {
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            bottom: em(-42px);
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            width: em(20px);
            height: em(20px);
            background-color: $white;
            border-top: 3px solid color('greys', light);
            border-left: 3px solid color('greys', light);
            opacity: 0;
            visibility: hidden;
            z-index: 1;
        }

        &:hover {
            .card__info {
                color: $color-primary;
            }
        }
    }

    .js-expanded &:before {
        transition: all $transition--fast linear;
        opacity: 1;
        visibility: visible;
    }

    &__expander {
        @extend .expander__content;
        line-height: 1.5;
        background-color: $white;

        .expander__wrapper {
            padding: 1.25em;
            border: 3px solid color('greys', light);
        }

        .h4 {
            margin: 1em 0 .5em;
            text-transform: uppercase;
        }

        ul {
            list-style-position: inside;
        }

        .expander__close {
            position: absolute;
            top: em(5px);
            right: em(10px);
            font-size: 1.5em;
            color: $base__font-color;
            cursor: pointer;
            transition: all $transition--fast;

            &:hover {
                color: lighten($base__font-color, 10%);
                transform: scale(1.1);
            }
        }
    }
}

.card--default {
    display: block;
}
