// ==========================================================================
// Mixins
// ==========================================================================


// Opacity /w ie fallback
// @include opacity(1);

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// Grayscale
// @include grayscale()

@mixin grayscale(){
  //Internet Explorer 6 - 9
  filter: gray;
  
  //Chrome 19+
  -webkit-filter: grayscale(100%);
  
  //Firefox 10+
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  
  //Standard
  filter: grayscale(100%);
}


// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// Vertical align
// @include vertical-align;

@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}