@include banner('.banner', false, sm, 6rem);

.banner {
    text-align: center;
    color: $color--primary;
}
.banner__title {
    color: inherit;
    @extend .section__title;
}
.banner__content {
    color: $root-color;
    margin: 2rem 0 0;
    font: 400 1rem/1.5 $font-family--secondary;
    @include mq(sm){
        font-size: 1.25rem;
    }
    p {
        margin: 0;
        font-size: .875em;
    }
}
.banner__ctas {
    @extend .row;
    margin-top: .5rem;

    .btn {
        margin: 1.5rem 1rem 0;
    }
}
.banner--white {
    @extend .banner;
    color: $white;
    &__cover {
        @extend .banner__cover;
    }
    &__inner {
        @extend .banner__inner;
    }
    .banner__content {
        color: $white;
    }
    .btn {
        @extend .btn--white;
    }
}
.banner--mensuel {
    .banner__title {}
}
.banner--news-footer {
    .banner__content {
        color: $snow-white;
    }
}
.banner--logo {
    svg {
        max-width: 90%;
    }

    &.logo-calendar {
        svg {
            max-width: 70%;
        }
    }
}
.logo-gala {
    width: 400px;
    max-width: 90%;
}


@include banner('.banner--home', 100vh, sm, 6rem);

.banner--home {

    @extend .banner--white;

    &__title {
        @extend .section__title;
        @include mq(sm){
            font-size: 75px!important;
        }
    }
    &__content {
        @extend .banner__content;
        color: $white;
    }
    .adisq-icon {
        margin-top: 2rem;
        @include mq(sm){
            margin-top: 4rem;
        }
    }
}

@include banner('.banner--mensuel', 550px, sm, 6rem);

.banner--mensuel {
    &__cover img {
        bottom: 0;
        top: auto;
        left: auto;
        right: 0;
        transform: translate3d(0,0,0);
        max-width: unset;
        opacity: .25;
        max-height: 100%;
        min-width: 0;
        @include mq(md){
            transform: translate3d(0,0,0);
            opacity: .5;
        }
        @include mq(lg){
            transform: translate3d(0,0,0);
            opacity: 1;
        }
    }
    &__inner {
        max-width: 100vw;
        width: $container-width;
        left: 0;
        color: $color--primary;
        @include mq(sm){
            transform: translate3d(0,-50%,0);
            text-align: left;
        }
        @include mq(lg){
            left: calc((100vw - #{$container-width})/2);
            max-width: 50%;
        }

    }
}

@include banner('.banner--news', 550px, sm, 6rem);

.banner--news {
    .banner__title,
    .banner__content {
        color: $snow-white;
    }
}

.banner--agendadisq {
    @extend .banner;
}

@include banner('.banner--subscription', false, sm, 4rem);

.banner--subscription {
    .lead__title,
    &__inner {
        color: $white;
    }
    .btn {
        @extend .btn--white;
    }
}


@include banner('.banner--gala', 550px, sm, 6rem);

.banner--gala {

    .banner--gala__inner {

        text-align: left;
        max-width: $container-width;
        .banner__title {
            color: $color--primary;
        }
        .banner__content {
            color: $white;
        }
        @include mq(lg){
            padding-left: calc(#{$container-width}/3);
        }
        .btn {
            @extend .btn--white;
        }

    }
    @include mq(lg){
            &:before {
                content: "";
                background: url(/dist/images/felix.png) top center no-repeat;
                top: -2rem;
                left: calc((100vw - #{$container-width})/2);
                position: absolute;
                z-index: 99;
                width: calc(#{$container-width}/3);
                height: 100%;
            }
        }
}

@include banner('.banner--training', false, sm, 4rem);

.banner--training {
    &__inner {
        padding-bottom: 0;
        color: $color--primary;
    }
    &+.section .section__inner{
        padding-top: 0;
    }
}

@include banner('.banner--top', 600px, sm, 6rem);

.banner--top {
    @extend .banner--white;
    .banner__content {
        color: $white;
    }
}

.banner--top-cta {
    @include mq(lg) {
        .lead.banner--top__inner {
            max-width: $container-width;

           .btn {
               font-size: 12px;
           }
        }
    }
}


[class^="banner--"] {
    &.pattern:after {
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        margin:auto;
        z-index: -1;
        background-image: url(../images/bg_dot.png);
        z-index: 40;
    }
    &.dark-layer:after {
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        margin:auto;
        z-index: -1;
        background-color: rgba(0,0,0,0.5);
        z-index: 40;
    }
    &.light-layer {
        .btn--primary {
            background-color: $color-primary;
            color: $white;
            border-color: $color-primary;

            &:hover {
                background-color: $white;
                color: $color-primary;
                border-collapse: $white;
            }
        }

        .btn--no-gradient {
            background-image: none;
        }

        &::before {
            content: "";
            display: block;
            width: 100%;
            background: rgb(2,0,36);
            background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.6) 100%);
            position: absolute;
            left: 0;
            top: 0;
            height: 85px;
            z-index: 50;
        }
    }
}
