.directory {
    &__anchors {
        padding: em(15px) 0;
        border-bottom: 2px solid color('greys', 'xlight');

        ul {
            list-style-type: none;
            text-align: center;
        }

        li {
            display: inline-block;

            &.disabled {
                color: #ccc;
                opacity: 1;
                pointer-events: none;
            }
        }

        a {
            display: block;
            width: em(40px);
            height: em(40px);
            line-height: em(40px);
            border-radius: 100%;
            font-family: $font-family--primary;
            font-weight: 500;
            text-transform: uppercase;
            transition: all $transition--fast;

            &:hover {
                color: $color--primary;
                text-decoration: none;
            }

            &.is-active {
                color: $white;
                background-color: $color--primary;
            }
        }
    }

    &__section {
        margin-bottom: em($grid-gutter-width*2);
    }

    .is-hidden {
        display: none;
    }

    &__title {
        margin-bottom: em($grid-gutter-width);
        font-family: $font-family--primary;
        font-size: em(20px);
        font-weight: 500;
        color: $color--primary;
    }

    &__entries {
        position: relative;
        list-style-type: none;

        .is-hidden {
            display: none;
        }
    }

    &__entry {
        position: relative;

        & > a {
            display: inline-block;
            margin-bottom: em(30px);
            transition: all $transition--fast;
        }

        [data-expander] & {
            &:before {
                content: '';
                position: absolute;
                bottom: em(-10px);
                left: em(20px);
                transform: rotate(45deg);
                width: em(20px);
                height: em(20px);
                background-color: $white;
                border-top: 3px solid color('greys', light);
                border-left: 3px solid color('greys', light);
                opacity: 0;
                visibility: hidden;
                z-index: 1;
            }
        }

        .js-expanded & {
            color: $color--primary;

            &:before {
                transition: all $transition--fast linear;
                opacity: 1;
                visibility: visible;
            }

            & > a {
                border-left: 3px solid $color--primary;
                padding-left: em(10px);
            }
        }
    }

    &__expander {
        @extend .expander__content;
        left: em($grid-gutter-width/2);
        right: em($grid-gutter-width/2);
        line-height: 1.5;
        background-color: $white;
        z-index: 1;

        .expander__wrapper {
            // margin-bottom: em(30px);
            padding: 2em 1.25em;
            border: 3px solid color('greys', light);
        }

        .expander__close {
            position: absolute;
            top: em(5px);
            right: em(10px);
            font-size: 1.5em;
            color: $base__font-color;
            cursor: pointer;
            transition: all $transition--fast;

            &:hover {
                color: lighten($base__font-color, 10%);
                transform: scale(1.1);
            }
        }

        .h4 {
            margin-bottom: em($grid-gutter-width/2);
            text-transform: uppercase;
        }

        .directory__address {
            margin-bottom: em($grid-gutter-width/2);
        }

        a {
            color: color('brand', 'primary');
        }

        img {
            max-width: 100%;
        }

        [class^="col-"] {
            margin-top: em($grid-gutter-width);
        }
    }

    @include mq(xs) {
        &__entries {
            margin-left: em(-$grid-gutter-width/2);
            margin-right: em(-$grid-gutter-width/2);
            @include clearfix();

            li {
                float: left;
                width: 50%;
                padding: 0 em($grid-gutter-width/2);
            }
        }
    }

    @include mq(sm) {
        [class^="col-"] {
            margin-top: 0;
        }
    }

    @media (min-width: 1090px) {
        &__anchors {
            ul {
                display: table;
                width: 100%;
            }

            li {
                display: table-cell;
            }
        }
    }
}
