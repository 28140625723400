// ========================================================
// Site header
// ========================================================

.site-header {
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
	padding: 15px 20px;
    //z-index: 50;
	//background-color: $color-primary;
	color: $white;
	//box-shadow: -2px 7px 31px -2px rgba(0,0,0,0.58);

    .adisq-icon {
        margin-right: 10px;
        width: 2.5rem;
        height: 2.5rem;
        &:before {
            font-size: 1.1rem;
            line-height: 2.2em;
        }
        @include mq(sm){
            margin-right: 20px;
            width: 3.25rem;
            height: 3.25rem;
            &:before {
                font-size: 1.25rem;
                line-height: 2.4em;
            }
        }
    }
    .nav__icon,
    .site-brand,
    .fa-calendar {
        z-index: 51;
        position: relative;
    }
    .fa-search {
        display: none;
        @include mq(sm){
            display: inline-block;
            z-index: 49;
        }
    }
}

.site-brand {
	display: inline-block;
	.logo-adisq {
        max-height: 40px;
        max-width: 142px;
        @include mq(sm){
    		max-height: 53px;
    		max-width: 185px;
        }
	}
	// Adisq writing
	.logo-adisq path {
		stroke: $white;

		& + path {
			fill: $white;
		}
	}
}

.icon-wrapper {
	position: absolute;
	top: 0; bottom: 0;
    right: 0;
	margin-left: 20px;
    z-index: 51;
	//margin-left: 77px;
	display: inline-block;
	width: 70px;
	//background-color: $color-primary--lighten;

	transition: all 350ms cubic-bezier(1,0,.61,.15);

	// push icon on nav side
	&.push {
		@media only screen and (min-width: $nohover-width) {
			margin-left: 80px;
		}
	}

}

.icon-menu {
	display: inline-block;
	// fix center
	//padding-top: 8px;
}

.icon-menu {
	position: absolute;

	left: 50%; top: 50%;
	transform: translate(-50%, -50%);
}

.hamburger {

	display: inline-block;
	width: 30px;
	height: 30px;
	padding: 3px;
	cursor: pointer;

	& > div {
		background: $white;
		height: 2px;
		margin-bottom: 4px;
		float: left;
		width: 100%;
	}

	& > div:nth-child(1),
	& > div:nth-child(3) {
		float: right;
	}
	& > div:nth-child(3) {
		//margin-bottom: 0;
	}

	&.active {
		transition: all 0.4s ease;
		transform:  scale(1, 1) rotateZ(180deg) translate(0, 4px);
		& > div {
		   transition: all 0.4s ease;
		}

		& > div:nth-child(1),
		& > div:nth-child(3) {
		  width: 70%;
		}

		& > div:nth-child(2) {
		  width: 90%;
		}

		& > div:nth-child(1) {
		    transform:  scale(1, 1) rotateZ(45deg) translate(2px, 2px);
		}

		& > div:nth-child(3) {
		  transform:  scale(1, 1) rotateZ(-45deg) translate(2px, -2px);
		}

		&.active-end {
			transform:  scale(1, 1) rotateZ(360deg) translate(0, 0);
			& > div {
				width: 100%;
			}
			& > div:nth-child(1) {
				transform:  scale(1, 1) rotateZ(0deg) translate(0, 0);
			}

			& > div:nth-child(3) {
				transform:  scale(1, 1) rotateZ(0deg) translate(0, 0);
			}
		}
	}
}
