.form {
    .form-item {
        input[type="search"].input--right-icon {
            padding-left: 2em;
            border: 2px solid #fff;
            border-radius: 50px;
            background: transparent;
        }
        input[type="search"].input--right-icon ~ .fa {
            top:0;
            border:0;
            font-size: 1.25rem;
            line-height: 3.25rem;
            color: #fff;
        }
    }
}
