//  ==========================================================================
//  Variables
//  ==========================================================================


//  Fonts Families
//  --------------------------

$ff--primary           : 'Libre Baskerville', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$ff--secondary         : 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$ff__path              : "../fonts/";
$ff__icon              : "";
$ff__fa                : "fontAwesome";

//  Colors (overwrite & extend the $colors map)
//  --------------------------
//  By default :
//  $colors : (
//      brand: (
//          primary     : $color--primary,
//          secondary   : $color--secondary
//      ),
//      greys : (
//          xdark       : lighten($color--black,20%),
//          dark        : lighten($color--black,35%),
//          base        : lighten($color--black,50%),
//          light       : lighten($color--black,65%),
//          xlight      : lighten($color--black,80%),
//      ),
//      parsley : (
//          error       : $color--error
//      )
//  )
//  Usage :
//  color: color('greys', 'xlight');
//  color: color('brand', 'primary');

$color--globalia    : #1982c4;
$color--white       : #FFFFFF;
$color--black       : #000000;
$color--error       : #ff0000;

$color--primary     : $color--globalia;
$color--secondary   : darken($color--globalia,20%);

$colors-project : (

);

//  Global variables
//  --------------------------
$root-color         : lighten($color--black,20%);
$root-font-family   : $ff--primary;
$root-font-size     : 100%;
$root-line-height   : 1.44;

$margin   : 1.5em;
$padding  : 1em;

$border   : 1px solid lighten($color--black,80%);
$radius   : .5em;






//  Headings
//  --------------------------
$h1 : $root-font-size * 2.25;   // 36px;
$h2 : $root-font-size * 1.75;   // 28px;
$h3 : $root-font-size * 1.5;    // 24px;
$h4 : $root-font-size * 1.25;   // 20px;
$h5 : $root-font-size * 1.125;  // 18px;
$h6 : $root-font-size;          // 16px;

//  Transitions
//  --------------------------
$transition--fast   : 240ms;
$transition--normal : 480ms;
$transition--slow   : 720ms;

//  Breakpoints
//  --------------------------
//  @example scss - Usage
//   @include mq(md) {...}
//
//  create mobile-first media-query ( min-width >= breakpoint )

$breakpoints : (
    root: 0,
    xs:   40em, // 640px
    sm:   48em, // 768px
    smd:  60em, // 960px
    md:   64em, // 1024px
    lg:   80em, // 1280px
    xl:   96em  // 1536px
);

//  Grid settings
//  --------------------------
//  @example scss - Usage
//     @include grid(wrapper-class, $grid-columns, $gutter-width, $container-width, flexbox-enabled?);
//     additionnal params:
//          wrapper-class (ex:'.grid')
//          flexbox-enabled (ex:false)
//
//  create responsive grid based on $breakpoints

$grid-columns       : 12;
$gutter-width       : 30px;
$container-width    : 1240px;

//  Components
//  --------------------------
$zindexes: (
    overlay             : -1,
    overlay-visible     : 9,
    nav                 : 40,
    nav-icon            : 41,
    nav-panel           : 39
);

//  Navigation
//  --------------------------
$nav-icon-bar-width         : 2px;
$nav-icon-bar-color         : $color--white;
$nav-height                 : 4rem;
$nav-mobile-width           : 400px;
$nav-mobile-animation       : ".will-push-right"; //Values: [".will-push-left", ".will-push-right", ".will-zoom-out"]
$nav-mobile-background      : $color--white;






// Aliases variables
$white              : $color--white;
$black              : $color--black;
$snow-white         : $color--white;
$darth-vader        : $color--black;
