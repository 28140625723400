[href*=".pdf"]:not(.btn):not(.adisq-icon),
[href*=".doc"]:not(.btn),
[href*=".xls"]:not(.btn) {
    position: relative;
    padding-left: 1.5rem;

    &:before {
        position: absolute;
        top: 2px;
        left: 0;
        @extend .fa;
        font-size: 1rem;
    }
}

[href*=".pdf"]:not(.btn):not(.adisq-icon) {
    &:before {
        @extend .fa-file-pdf-o;
        color: #ca2d2f;
    }
}

[href*=".doc"]:not(.btn) {
    &:before {
        @extend .fa-file-word-o;
        color: $color--primary;
    }
}

[href*=".xls"]:not(.btn) {
    &:before {
        @extend .fa-file-excel-o;
        color: #43af57;
    }
}

.document-list {
    list-style: none;
    font-family: $font-family--secondary;

    li {
        margin-bottom: em($grid-gutter-width);

        &.doc {
            margin-bottom: em($grid-gutter-width/2);
        }
    }

    &__desc {
        margin-top: em(10px);
    }

    a {
        display: block;
        font-weight: 600;

        & + a {
            margin-top: em(5px);
        }
    }

    &--sub {
        list-style: none;
        margin-bottom: em(64px);
        font-family: $font-family--secondary;

        li {
            padding: em(25px) 0;

            & + li {
                border-top: 1px solid color('greys', 'xlight');
            }
        }

        a {
            display: block;
            font-weight: 600;

            & + a {
                margin-top: em(5px);
            }
        }

        time {
            @extend .col-sm-3;
            display: block;
            padding-bottom: em($grid-gutter-width/2);
            color: $color-primary;
        }

        .document-list__content {
            @extend .col-sm-9;
        }
    }

    @include mq(sm) {
        &--sub {
            time {
                padding-bottom: 0;
            }
        }
    }
}
