.rencontre {
    &__infos {
        margin-bottom: 1em;
        font-family: $font-family--secondary;
        color: $color--primary;
    }

    &__detail {
        p {
            margin-bottom: .5em;
            line-height: 2;
        }
        ul,
        .p {
            margin-bottom: .5em;
            margin-left: 1.5em;
            line-height: 2;
        }
    }

    &__person {
        //margin-top: em($grid-gutter-width);
        @extend .col-sm-4;

        @include mq(sm) {
            &:nth-child(4n) {
                clear: left;
            }
        }

        div::first-line {
            font-weight: 600;
        }

        img {
            float: left;
            margin-right: em($grid-gutter-width/2);
            max-width: 100px;
            height: auto;
        }
    }

    &__training {
        margin: 0 em(15px) 1em em(15px);

        img {
            float: left;
            margin-right: em($grid-gutter-width/2);
            max-width: 100px;
            height: auto;
        }
    }
}
.card-price {
    .card__title {
        line-height: 1.25;
    }
}

.training {
    ul,
    .p {
        margin-bottom: .5em;
        margin-left: 1.5em;
        line-height: 2;
    }
}
