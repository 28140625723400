/* ==========================================================================
    Table
   ========================================================================== */
$table-border-color: color('greys', 'xlight') !default;
$table-striped-color: #e4e4e4 !default;
$table-alert: #e74c3c;
$table-sucess: #27ae60;

// Tables
table, th, td {
   border: none;
}

.table {
    width:100%;
    display: table;
    
    thead {
        background-color: $color--primary;
        font-family: $ff--secondary;
        text-transform: uppercase;
        color: $white;
    }
    
    td, th {
        padding: 10px 15px;
        display: table-cell;
        text-align: left;
        vertical-align: middle;
        font-size: em(14px);
        
        a + a {
            margin-left: 15px;
        }
        
        &.col-avatar {
            max-width: 150px;
            width: 150px;
        }
    }
    
    .avatar {
        width: 70px;
        height: 70px;
        border-radius: 100%;
    }
    
    .sucess {
        color: $table-sucess;
    }
    
    .alert {
        color: $table-alert;
    }

    &.bordered > thead > tr,
    &.bordered > tbody > tr {
        border-bottom: 1px solid $table-border-color;
    }

    &.striped > tbody {
        > tr:nth-child(odd) {
          background-color: $table-striped-color;
        }

        > tr > td {
          border-radius: 0px;
        }
    }

    &.highlight > tbody > tr {
        transition: background-color .25s ease;

        &:hover {
            background-color: $table-striped-color;
        }
    }
}

@media (max-width: #{map_get($breakpoints, 'sm')}) {
    .table.responsive-table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        display: block;
        position: relative;

        th,
        td {
            margin: 0;
            vertical-align: top;
        }

        th {
            text-align: left;
        }

        thead {
            display: block;
            float: left;

            tr {
                display: block;
                padding: 0 10px 0 0;

                th::before {
                    content: "\00a0";
                }
            }
        }

        tbody {
            display: block;
            width: auto;
            position: relative;
            overflow-x: auto;
            white-space: nowrap;

            tr {
                display: inline-block;
                vertical-align: top;
            }
        }

        th {
            display: block;
            text-align: right;
        }

        td {
            display: block;
            min-height: 1.25em;
            text-align: left;
        }
        tr {
            padding: 0 10px;
        }

        /* sort out borders */
        thead {
            border: 0;
            border-right: 1px solid $table-striped-color;
        }

        &.bordered {
            th { border-bottom: 0; border-left: 0; }
            td { border-left: 0; border-right: 0; border-bottom: 0; }
            tr { border: 0; }
            tbody tr { border-right: 1px solid $table-striped-color; }
        }
    }
}
   