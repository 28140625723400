@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon/icomoon.eot?mlmec');
	src:url('../fonts/icomoon/icomoon.eot?mlmec#iefix') format('embedded-opentype'),
		url('../fonts/icomoon/icomoon.ttf?mlmec') format('truetype'),
		url('../fonts/icomoon/icomoon.woff?mlmec') format('woff'),
		url('../fonts/icomoon/icomoon.svg?mlmec#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-01:before {
	content: "\e600";
}
.icon-02:before {
	content: "\e601";
}
.icon-03:before {
	content: "\e602";
}
.icon-04:before {
	content: "\e603";
}
.icon-05:before {
	content: "\e604";
}
.icon-06:before {
	content: "\e605";
}
.icon-07:before {
	content: "\e606";
}
.icon-08:before {
	content: "\e607";
}
.icon-09:before {
	content: "\e901";
}
.icon-10:before {
	content: "\e900";
}
.icon-11:before {
    content: "\e908";
}
.icon-12:before {
    content: "\e905";
}
.icon-13:before {
    content: "\e906";
}
.icon-14:before {
    content: "\e907";
}
.icon-15:before {
    content: "\e902";
}
.icon-16:before {
    content: "\e903";
}
.icon-17:before {
    content: "\e904";
}
.icon-18:before {
    content: "\e909";
}
.icon-19:before {
    content: "\e90a";
}