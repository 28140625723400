
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 400;
}

h1, .h1 {
    font-size: em(26px);
    color: $color-primary;

    //margin-bottom: em(15px, 26px);

    @media only screen and (min-width: $xsmall-width) {
        font-size: em(60px);
        //margin-bottom: em(50px, 60px);
    }
}

h2, .h2 {
    font-size: em(26px);
    color: $color-primary;

    //margin-bottom: em(15px, 26px);

    @media only screen and (min-width: $xsmall-width) {
        font-size: em(60px);
        //margin-bottom: em(50px, 60px);
    }
}

h3, .h3 {
    font-family: $font-family--primary;
    font-weight: $font-weight--bold;
    font-size: em(16px);
    text-transform: uppercase;
    color: $color-primary;

    @media only screen and (min-width: $xsmall-width) {
        font-size: em(18px);
    }
}

h4, .h4 {
    font-family: $font-family--primary;
    font-weight: $font-weight--bold;
    font-size: em(16px);

    @media only screen and (min-width: $xsmall-width) {
        font-size: em(18px);
    }
}



p {
    font-size: em(14px);

    @media only screen and (min-width: $xsmall-width) {
        font-size: em($base__font-size);
    }
}

strong, .strong {
    font-weight: $font-weight--bold;
}

a {
    color: inherit;
    text-decoration: none;

    @media only screen and (min-width: $nohover-width) {
        &:hover {
            text-decoration: underline;
        }
    }

}

.section-title {
    font-weight: 400;

    p {
        font-family: $font-family--primary;
    }
}

// wysiwyg stylesheet -- indepedent stylesheet? duplicate code? [NOW it take base style]
.wysiwyg-container {
    p {
        line-height: 2;
        margin-bottom: 10px;
        @media only screen and (min-width: $xsmall-width) {
            margin-bottom: 25px;
        }
    }

    a {
        color: $color-primary;
    }
}
