// ==========================================================================
// Leaves - Lives as components
// ==========================================================================

//@import "typo";
//@import "tags";

//@import "social-icons";


sub, sup {
    font-size: 50%;
}

ul.row {
    list-style: none;
}

@include nav-icon('.nav__icon', 83px);

.nav__icon {
    position: relative;
    float: right;
    top: 0;
}



.time {
    font: 400 1em/3.25 $font-family--secondary;
    display: block;
    position: relative;
    margin-bottom: 1em;
    &:after {
        content: "";
        height: 0;
        width: 40px;
        border-top-style: solid;
        border-top-width: 4px;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.btn-wrapper {
    text-align: center;
    padding-top: 3em;
}

@import "expander";
@import "cta-cards";
@import "directory";
@import "document-lists";
@import "banners";
@import "sections";
@import "calendar";
@import "slider";
@import "tooltip";
@import "typo";
@import "news";
@import "rencontre";
@import "search";
@import "privilege";
@import "calendar-layout";
@import "table";
@import "optionals";
@import "helpers";

.twitter-feed {
    padding-bottom: 3em;
    padding-top: 2em;
    h3 {
        font-size: 1.25em;
    }

    @include mq(sm) {
        padding-left: 1em;
    }

    @include mq(md) {
        padding-left: 4em;
    }
}

.list--tweet {
    ul {
        list-style: none;
    }

    li,
    .content {
        border-bottom: 1px solid #ece7e2;
        padding-bottom: 1.5em;
        padding-top: 1.5em;
        a {
            color: $color-primary;
        }
        .user,
        .date {
            display: inline-block;
            font: 400 .875em/1 $font-family--primary;
            text-transform: uppercase;
        }
        .tweet {
            margin-top: .5em;
            display: block;
        }
        [data-scribe="element:screen_name"] {
            display: none;
        }
    }
}

.pagination {
    padding: 3em 0;
    ul {
        list-style: none;
        text-align: center;
        li {
            display: inline-block;
            height: 2.5em;
            width: 2.5em;
            text-align: center;
            a {
                font: 400 1em/2.5 $font-family--primary;
                &:not(.active):hover {
                    text-decoration: none;
                    color: $color-primary;
                }
            }
            &.active {
                background: $color-primary;
                border-radius: 1.25em;
                color: $white;
            }
        }
    }
}

.main-ctas {

    overflow: hidden;
    .main-cta-card {
        @extend .col-xs-12;
        @extend .col-sm-6;
        @extend .col-md-3;
        > img {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: auto;
            transform: translate3d(-50%,-50%,0);
            width: 105%;
            height: auto;
        }
        &:before {
            content:'';
            display: block;
            width: 100%;
            padding-top: calc(100% * 5/6);
        }
        .main-cta-card__inner {
            display: block;
            position: absolute;
            top: 75%;
            left: 50%;
            margin: auto;
            transform: translate3d(-50%,-50%,0);
            width: 100%;
            height: auto;
            text-align: center;
        }
        background: #ddd;
    }
}

$subnav-tab-height: 4.5rem;

.sub-nav {
    @include mq(sm) {
        border-bottom: 2px solid #e4e4e4;
    }
}

.sub-nav__wrapper {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    margin-top: $gutter-width;
    margin-bottom: $gutter-width;
    border: 2px solid #e4e4e4;

    &.is-open {
        .sub-nav__link {
            &:not(.is-active) {
                display: block;
            }

            &.is-active {
                &:after {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }
    }

    @include mq(sm) {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        border: 0;
    }
}

.sub-nav__link {
    display: block;
    height: $subnav-tab-height;
    padding: 1.6rem 2rem;
    font: 400 .8125rem/1.1 $font-family--primary;
    color: $gray-light;
    text-transform: uppercase;
    text-decoration: none;
    transition: all .375s cubic-bezier(0, 0.5, 0.5, 1);
    color: $root-color;

    @media only screen and (max-width: ($small-width - 1) ) {
        &:not(.is-active) {
            //display: none;
        }

        span {
            vertical-align: middle;
        }

        &.is-active {
            position: absolute;
            top: 0;
            width: 100%;
            display: block;
            background-color: $color-primary;
            color: $white;
        }
    }


    strong {
        font-size: .875rem;
        font-weight: 700;
    }

    @include mq(sm) {
        position: relative;
        top: auto;
        display: table-cell;
        vertical-align: middle;
        padding: 0 2rem;
        text-align: center;
        color: #ccc;

        &.is-active {
            box-shadow: inset 0 -4.5rem $color-primary;
            color: $white;
            text-decoration: none;
        }

        &:not(.is-active):hover {
            box-shadow: inset 0 -4.5rem #e4e4e4;
            color: $color-primary;
            text-decoration: none;
        }

        &:after {
            display: none;
        }
    }
}


.tab__wrapper {
    @extend .sub-nav__wrapper;
}

.tab__link {
    @extend .sub-nav__link;

    // @include mq(sm) {
    //     box-shadow: inset 0 -3px $color-primary;
    // }
}

@include form('.form', 3.25rem, $border, 0);


.sitemap {
    list-style: none;

    &>li {
        font-weight: bold;
        margin-bottom: .75em;

        ul {
            margin-left: 1rem;
            font-weight: normal;
        }
    }
}

.member__cost {
    color: $color--primary;
}

.banner--mensuel {
    .banner--top__inner {
        @include mq(md) {
            &>* {
                transform: none;
                -webkit-filter: none;
            }
        }
    }
}

.form--search-top {
    .form-item {
        width: 75%;
        margin: 0 auto;
    }

    input[type="search"] {
        padding-left: 2em;
        border: 2px solid #fff;
        border-radius: 50px;
        background: transparent;

        &.input--right-icon ~ .fa {
            top: 0;
            border: 0;
            font-size: 1.25rem;
            line-height: 3.25rem;
            color: #fff;
        }
    }
}

input[type="checkbox"],
input[type="radio"] {
    & + label {
        padding-left: 1em;
    }
}


/* Ross fix Evenement */

.btn--small {
    padding: 10px;
}


.form--submit-event.form {

    .form-item,
    .form-item--inline {
        //margin-bottom: 2.5em;
        overflow: hidden;

        label {
            letter-spacing: .5px;
            display: table-cell;
            vertical-align: bottom;
            height: 46px;
        }

        input + label {
            display: inline-block;
            height: auto;
        }
    }

}

.form-item--autocomplete {

    @include mq(md) {
        max-width: 50%;

    }

    [id=addArtisteTop] {
        margin-top: 1em;
    }

    .artiste-autocomplete-list {
        list-style: none;
        margin-top: 1em;

        li {
            padding: .3em 1em;

            a {
                text-transform: uppercase;
                padding-right: 1em;
                font-size: em(14px);
                font-weight: bold;
                color: #1982c4;
                font-family: arial;
                letter-spacing: .5px;
            }
        }
    }
}

.resultArtiste {
    margin-top: .5em;
    list-style: none;

    li {
        padding: .3em 1em;
        cursor: pointer;

        &:hover {
            color: #fff;
            background-color: #1982c4;
        }
    }
}

.button-container {

    border-top: 1px solid #1982c4;
    padding-top: 1.5em;

    * {
        margin-bottom: 1em;
    }

    @include mq(sm) {
        * + * {
            margin-left: 1em;
        }
    }
}



.btn--retour {
    padding-left: $spacing-xlarge-horizontal !important;

    @media only screen and (min-width: $xsmall-width) {
        padding-left: $spacing-xxlarge-horizontal !important;
    }

    &:after {
        position: absolute;
        top:50%; left: 25px;
        transform: translateY(-50%);

        content: "\f060";
        font-family: $font-family--icon;
    }
}

.u-spacing-top-2 {
    margin-top: 2rem;

    @include mq(md) {
        margin-top: 0;
    }
}

.archive-search {
    .select {
        width: 100%;
    }

    .input-group {
        border-bottom: 2px solid #ece7e2;
        width: 100%;
        margin-bottom: 0;
    }

    .select-styled {
        border-bottom: none;
    }

    button {
        cursor: pointer;
    }
}

.archive-search__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    flex-direction: column;

    @include mq(sm) {
        flex-direction: row;
    }
}

.archive-search__col {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    padding: 0 15px;

    @include mq(sm) {
        flex: 1 0 33.333%;
        max-width: 33.333%;
        margin-top: 0;
    }
}

.archive-download {
    text-align: center;
    margin-top: 2rem;
}
