/* ==========================================================================
   Login 
   ========================================================================== */

.form--login {
    padding: 30px;
    border: 2px solid $color--primary;
    margin-bottom: em($gutter-width);
    
    h4 {
        font-size: em(22px);
        font-weight: 500;
        margin-bottom: em($gutter-width/2);
        color: $color--primary;
    }
    
    .btn-group {
        p + p {
            margin-top: em(10px);
        }
        
        a {
            font-family: $ff--secondary;
            font-size: em(14px);
            color: $color--primary;
            text-transform: uppercase;
        }
    }
}

.form--center {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.form--submit-event {
    input[type="file"] {
        margin-top: em($gutter-width/2);
    }
    
    /* Select2 override
       ========================================================================== */
       
    .select2-container--default .select2-selection--multiple {
        border-radius: 0;
        border-color: #cccccc;
        padding-left: 1em;
        padding-right: 1em;
        min-height: 3.25rem;
    }

    .select2-container--default.select2-container--open .select2-selection--multiple {
        border-color: $color--primary;
    }
    
    .select2-container--default .select2-selection--multiple .select2-selection__rendered {
        padding-bottom: 7px;
        padding-top: 7px;
    }

    .select2 {
        input[type="search"] {
            height: auto;
            line-height: 1.4;
            //margin-top: 0;
        }
    }
}

/* Select2 override
   ========================================================================== */
   
.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: $color--primary;
    color: $white;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #ddd;
    color: $root-color;
}

.form-validation {
    margin-top: em($gutter-width);
    margin-bottom: em($gutter-width);
    
    &--warning {
        color: $color--error;
    }
}

.welcome {
    position: relative;
    font-family: $ff--secondary;
    z-index: 999;
    margin-top: 25px;
    margin-right: em($gutter-width);
}