.btn {
	position: relative;
	display: inline-block;
	padding: 15px 20px;
    min-width: 170px;

	border: 2px solid $color-primary;
	border-radius: $br--round;
    outline: 0;

	font-family: $font-family--primary;
	font-weight: $font-weight--bold;
	font-size: em(14px);
	color: $color-primary;
	text-align: center;
	text-transform: uppercase;

	background-size: 200% 100%;
	background-image: linear-gradient(to right, transparent 50%, $color-primary 50%);
	transition: background-position .3s cubic-bezier(0.19, 1, 0.22, 1) .1s, color .5s ease 0s, background-color .5s ease;

	@media only screen and (min-width: $xsmall-width) {
		padding: 22px 30px;
		font-size: em(14px);
	}



	&:hover {
		@media only screen and (min-width: $nohover-width) {
			text-decoration: none;
			color: $white;
			background-color: $color-primary;
			background-position: -100% 100%;
		}

	}


	&--white {
		color: $white;
		border-color: $white;
		background-image: linear-gradient(to right, transparent 50%, $white 50%);

		&:hover {
			@media only screen and (min-width: $nohover-width) {
				color: $color-primary;
				background-color: $white;
			}
		}
	}

	&--primary {
		padding-right: $spacing-xlarge-horizontal !important;

		@media only screen and (min-width: $xsmall-width) {
			padding-right: $spacing-xxlarge-horizontal !important;
		}

		&:after {
			position: absolute;
			top:50%; right: 25px;
			transform: translateY(-50%);

			content: "\f061";
			font-family: $font-family--icon;
		}


	}

	&--back {
		padding-left: $spacing-xlarge-horizontal;

		@media only screen and (min-width: $xsmall-width) {
			padding-left: $spacing-xxlarge-horizontal;
		}

		&:after {
			position: absolute;
			top:50%; left: 15px;
			transform: translateY(-50%);

			content: "\f060";
			font-family: $font-family--icon;

			@media only screen and (min-width: $small-width) {
				left: 25px;
			}
		}

	}

	&--pdf {
		padding-right: $spacing-xlarge-horizontal;

		@media only screen and (min-width: $xsmall-width) {
			padding-right: $spacing-xxlarge-horizontal;
		}

		&:after {
			position: absolute;
			top:50%; right: 15px;
			transform: translateY(-50%);

			content: "\f1c1";
			font-family: $font-family--icon;
			font-weight: $font-weight--medium;

			@media only screen and (min-width: $small-width) {
				right: 25px;
			}
		}
	}
}

.btn-group {
    margin-top: .5rem;

	&__item {
		margin: 1.5rem 1rem 0px;

		@media only screen and (min-width: $medium-width) {
			text-align: left;
			&:first-of-type:not(.btn-group__item--centered) {
				text-align: right;
			}
		}

        &--centered {
            text-align: center;
        }
	}
}

.btn-close {
    border: 2px solid $color-primary;
    border-radius: 100%;
    width: em(50px, 14px);
    height: em(50px, 14px);
    font-size: em(14px);
    line-height: em(46px, 14px);
    text-align: center;
    transition: background-color .3s;
    cursor: pointer;

    &::before {
        content: "\f00d";
        display: inline-block;
        font-family: FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translate(0, 0);
        color: $color-primary;
        transition: color .3s;
    }

    &:hover {
        background-color: $color-primary;

        &::before {
            color: $white;
        }
    }

    &--white {
        border-color: $white;

        &::before {
            color: $white;
        }

        &:hover {
            background-color: $white;

            &::before {
                color: $color-primary;
            }
        }
    }
}

.adisq-icon-wrapper {
	&.active {
		.adisq-icon {
			transform: rotate(180deg);
		}
	}
}

.adisq-gala-icon {
	position: relative;
	display: inline-block;
	width: 30px;
	height: 30px;
	text-align: center;
	color: $color-primary;
    outline: 0;

	border-radius: 50%;
	cursor: pointer;
	z-index: 1;

	&:after {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 50%;
		pointer-events: none;
	}

	&:before {
		font-family: $font-family--icon;
		speak: none;
		font-size: 1em;
		line-height: 30px;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		display: block;
		-webkit-font-smoothing: antialiased;
	}

	&:hover {
		@media only screen and (min-width: $nohover-width) {
			text-decoration: none;
		}
	}
}

.adisq-icon {
    position: relative;
    display: inline-block;
    width: 3.25rem;
    height: 3.25rem;
    text-align: center;
    color: $white;
    outline: 0;

    border-radius: 50%;
    cursor: pointer;
    z-index: 1;

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 50%;
        pointer-events: none;
    }

    &:before {
        font-family: $font-family--icon;
        speak: none;
        font-size: 1.25rem;
        line-height: 2.5em;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        display: block;
        -webkit-font-smoothing: antialiased;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &:hover {
        @media only screen and (min-width: $nohover-width) {
            text-decoration: none;
        }
    }
}

// Use js-btn-top for the functionality
// <a href="#" class="btn-top btn-top--mobile js-btn-top"><i class="fa fa-angle-up fa-2x"></i></a>
.btn-top {
	//position: fixed;
	//bottom: 20px;
    //right: 20px;
    position: absolute;
    bottom: 60px;
    right: 50px;
    opacity: 0;
	width: 38px;
	height: 38px;
	//background-color: $color-primary;
	border: 2px solid $color-primary;
	visibility: hidden;
	color: $color-primary;
	text-decoration: none;
	text-align: center;
    transform: translate3d(0, 10px, 0);
	border-radius: 100%;
	box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.2);
	transform: translateZ(0);
    -webkit-transform: translateZ(0);
	@extend %transition-base;

	.fa {
		// (parent height) - (3px)
		line-height: 35px;
	}


	// Modifiers
    &.visible {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, 0, 0);
        @media only screen and (min-width: $nohover-width) {
            &:hover {
                //background-color: $color-primary;
                transform-style: preserve-3d;
                transform: scale(1.1) translateZ(0);
            }
        }

    }

}
