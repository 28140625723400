// ========================================================
// Site section
// ========================================================

.site-section {
	// Site-section spacing controler
	padding-top: 30px;
	padding-bottom: 30px;

	@media only screen and (min-width: $small-width) {
		padding-top: 100px;
		padding-bottom: 100px;
	}

	@media only screen and (min-width: $medium-width) {
		padding-top: 200px;
		padding-bottom: 200px;
	}

	@extend %bg-cover;

	// Modifiers
	// Background controler --> they all extend %bg-cover

	/* --------------- GLOBLAL  ---------------*/
	&--darkblue {
		background-color: $daintree;
	}

    &--grey {
        background-color: #f3f3f3;
    }


	/* --------------- PAGE GALA  ---------------*/
	&--vote {
		background-image: url("/images/bg-vote.jpg");
		@extend %bg-cover;

		.btn--primary{
			padding-right: 40px;
		}
	}

	&--statement {
		background-image: url("/images/bg-statement.jpg");
	}

	&--team-gala {
		background-image: url("/images/bg-footer.jpg");
		@extend %bg-cover;
	}

	/*---------------- PAGE NOMINATION --------------------*/
	&--industrial {
		background-image: url("/images/bg-statement.jpg");
		// _cheat
		.card {
			color: $white;
		}
	}


	/* --------------- PAGE ASSOCIATION  ---------------*/
	&--membership {
		background-image: url("/images/bg-forces.jpg");
	}

	&--council {
		background-image: url("/images/bg-council.jpg");
	}


	/* --------------- PAGE PROCESS  ---------------*/
	&--census {
		background-image: url("/images/min/bg-blue-guitare.jpg");
	}
	&--publicities {
		background-image: url("/images/min/bg_process-publicites.jpg");
	}

}


// ========================================================
// SPECEFIC site section
// ========================================================


/* --------------- Page gala  ---------------*/

.home-process {
	position: relative;
	overflow: hidden;

	&__img {
		position: absolute;
		right: -50px; bottom: 0;
		z-index: -1;
		@include opacity(0.4);

		img {
		    width: 185px;
		}

		@media only screen and (min-width: $xsmall-width) {
			img {
			    width: 100%;
			}
		}

		@media only screen and (min-width: $small-width) {
			right: 50px;
		}


		@media only screen and (min-width: $large-width) {
			right: 25px;
			@include opacity(1);
		}
	}

	&__content {
		z-index: 10;
	}
}
.vote {

	&__content {
		padding-top: 30px;
		padding-bottom: 30px;

		@media only screen and (min-width: $xsmall-width) {
			padding-top: 60px;
			padding-bottom: 60px;
		}

		@media only screen and (min-width: $small-width) {
			padding-top: 100px;
			padding-bottom: 100px;
		}

		@media only screen and (min-width: $medium-width) {
			padding-top: 200px;
			padding-bottom: 200px;
		}
	}

	&__img {
		display: none;

		@media only screen and (min-width: $medium-width) {
			display: block;
			margin-left: -100px;
			margin-top: 0;
			padding-top: 200px;
		}

		@media only screen and (min-width: $large-width) {
			padding-top: 0;
			margin-left: -300px;
			margin-top: -50px;
		}
	}
}
.partners{
	padding-bottom: 50px;

	@media only screen and (min-width: $small-width) {
		padding-top: 100px;
		padding-bottom: 100px;
	}

	@media only screen and (min-width: $medium-width) {
		padding-top: 200px;
		padding-bottom: 200px;
	}

	h2 {
		margin-bottom: 0;
	}
	&__logo {
        text-align: center;

		a {
			width: auto;
			display: inline-block;
			margin-right: 15px;
			margin-top: 15px;
			line-height: 50px;

		    @media only screen and (min-width: $small-width) {
				margin-right: 30px;
				margin-top: 30px;
				line-height: 100px;
		    }
		}

		a, img {
            max-height: 60px;
			max-width: 90px;
			vertical-align: bottom;

			@media only screen and (min-width: $small-width) {
				vertical-align: middle;
				max-height: 90px;
				max-width: 200px;
		    }
		}

	}

}
.team-gala {
	&__content {
		padding-top: 30px;
		padding-bottom: 30px;

		@media only screen and (min-width: $small-width) {
			padding-top: 100px;
			padding-bottom: 100px;
		}

		@media only screen and (min-width: $medium-width) {
			padding-top: 200px;
			padding-bottom: 200px;
		}
	}

	&__img {
		display: none;
		@media only screen and (min-width: $large-width) {
			display: block;
			margin-top: -50px;
		}
	}
}

.jumbotron__img{
	img.trophy{
		max-height: 210px;
		@media only screen and (min-width : $xxsmall-width){
			margin-right: 20px;
		}
		@media only screen and (min-width : $small-width){
			margin-right: 40px;
		}
	}
}

#logo_title{
	fill:white;
	@media only screen and (min-width : $xxsmall-width){
		width: 60%;
	}
	@media only screen and (min-width : $small-width){
		width: auto;
	}
}

.jumbotron--gala{
	max-height: 600px;
}




/* --------------- Page association  ---------------*/

.adisq-intro {
	padding-top: 30px;

	@media only screen and (min-width: $xsmall-width) {
		padding-top: 80px;
	}

	&__content {
		padding-bottom: 30px;

		@media only screen and (min-width: $xsmall-width) {
			padding-bottom: 80px;
		}
	}

	&__img {
		display: none;
		@media only screen and (min-width: $large-width) {
			display: block;
			margin-top: -140px;
			margin-left: -100px;
			margin-right: 25px;
		}
	}
}
.membership {
	&__row {
        position: relative;
		margin-top: em(50px);
		margin-bottom: em(20px); // (-) card margin-bottom(30px)
	}
}
.become-member {
	position: relative;

    @media only screen and (min-width: $medium-width) {
    	padding-top: 0;
    	padding-bottom: 0;
		.vcenter {
			min-height: 735px;
		}
	}

	&__img {
		position: absolute;
		right: -0; bottom: 0;
		@include opacity(0.4);
		z-index: -1;

		img {
		    width: 280px;
		}

		@media only screen and (min-width: $xsmall-width) {
			img {
			    width: 100%;
			}
		}
		@media only screen and (min-width: $small-width) {
			right: -100px;
		}

		@media only screen and (min-width: $large-width) {
			right: 25px;
			@include opacity(1);
		}
	}
}

/*---------------- Archives --------------------*/

.input-group {
    position: relative;
    margin-bottom: 30px;

    .adisq-icon-wrapper {
		position: absolute;
		right: 0; top: 50%;
		transform: translateY(-50%);
	}

    @media only screen and (min-width: $small-width) {
        margin-bottom: 50px;
        width: 460px;
        margin-left: auto;
        margin-right: auto;
    }

    @media only screen and (min-width: $medium-width) {
        margin-bottom: 0;
        width: 100%;
    }
}

.archives,
.directory {
	text-align: center;

	input[type="text"] {
		position: relative;
		padding-right: 50px;
	}

    .section__inner {
        z-index: 42;
    }
}
.filter {
	.btn {
		min-width: 100px;
	}
	li {
		display: inline-block;
		margin-bottom: 40px;
		@media only screen and (min-width: $medium-width) {
			margin-right: 15px;
			margin-left: 15px;
		}

	}
}

// ========================================================
// Accueil -  Section : TEA
// ========================================================

.team-gala__photo{
	text-align: center;
    padding-top: 5rem;
}
