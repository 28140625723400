.adisq-form {

	// Input
	input {
		outline: 0;
		width: 100%;
		-webkit-border-radius:0px;
	}

	input[type="text"] {
		padding-left: 5px;
		padding-top: 25px;
		padding-bottom: 25px;
		border: none;
		border-bottom: 2px solid $gray-xlight;
	}

	.btn-icon,
	.btn-icon:after,
	.btn-icon:before {
		color: $color-primary;
	}
}