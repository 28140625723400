// ========================================================
// Site menu
// ========================================================

// Off-canvas menu

.site-canvas {
	position: relative;
	/*width: 100%;
	height: 100%;

	transform: translateX(0);
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	// Orbit Animation
	transition: all 750ms cubic-bezier(1,0,.61,.15);
	// Snappy Animation
	// transition: transform .33s cubic-bezier(.694, .0482, .335, 1);*/
}
.show-nav .site-canvas .site-menu{
	transform: translateX(-100vw);
	transform: translate3d(-100vw, 0, 0);

    @media screen and (min-width:30em){
        transform: translateX(-380px);
        transform: translate3d(-380px, 0, 0);
    }
}

.site-menu {
	width: 100vw;
	height: 100%;
	position: absolute;
	top: 0;
    right: -100vw;
	background-color: $color-primary--darken;
	padding-top: 90px;
	color: $white;
	z-index: 101;
    transition: all 480ms;
    @media screen and (min-width:30em){
        width: 380px;
        right: -380px;
    }

    .form--search {
        border: 2px solid white;
        border-radius: 1.875rem;
        height: 3.75rem;
        line-height: 3.5rem;
        margin: 1.125rem 1.5rem;

        :focus {
            outline-style: none;
            box-shadow: none;
        }

        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: rgba(255,255,255,.5);
        }
        ::-moz-placeholder { /* Firefox 19+ */
          color: rgba(255,255,255,.5);
        }
        :-ms-input-placeholder { /* IE 10+ */
          color: rgba(255,255,255,.5);
        }
        :-moz-placeholder { /* Firefox 18- */
          color: rgba(255,255,255,.5);
        }



        input,
        button {
            float: left;
        }
        input {
            width: calc(100% - 3.25rem - 1.5rem);
            height: 100%;
            margin-left: 1.5rem;

            &:focus {
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                  color: rgba(255,255,255,.25);
                }
                &::-moz-placeholder { /* Firefox 19+ */
                  color: rgba(255,255,255,.25);
                }
                &:-ms-input-placeholder { /* IE 10+ */
                  color: rgba(255,255,255,.25);
                }
                &:-moz-placeholder { /* Firefox 18- */
                  color: rgba(255,255,255,.25);
                }
            }

        }
    }
}


// Main navigation

.sm-nav {
	font-family: $font-family--primary;
    display: table;
    width: 100%;

	&__item,
	li {
		list-style: none;
        position: relative;

		& > span,
        & > a {
			position: relative;
			display: block;
            width: 100%;
            text-transform: uppercase;
			color: $white;
			text-decoration: none;
            text-align: right;
            font-size: 1.125rem;
            line-height: 1.25rem;
            padding: 1.125rem 1.5rem;
            &+ul {
                background-color: rgba(255,255,255,.1);
                display: none;
            }
			&.active {
				&+ul {
                    display: block;
				}
			}

            .is-touch & {
                &+ul {
                    display: block;
                }
            }
		}

        & > span {
            &:after {
                content: $fa-var-angle-down;
                font-family: $font-family--icon;
                color: inherit;
                margin-left: .75rem;
            }
        }
	}

	&__icon {
		display: inline-block;
		font-size: 1.6em;
		margin-right: 25px;

		// Need refactor
		a {
			&:after {
				display: none;
			}
		}

	}
}
