// ==========================================================================
// Animations
// ==========================================================================

.adisq-icon-effect {
	.adisq-icon {
		box-shadow: inset 0 0 0 2px $white; //rgba(0, 120, 189, 1);
		overflow: hidden;
		transition: background 0.3s, color 0.3s, box-shadow 0.3s;

		&:after {
			display: none;
		}

		&:hover {
			@media only screen and (min-width: $nohover-width) {
				background: $white;
				color: $color-primary;
				box-shadow: 0 0 0 4px rgba(0, 120, 189, 0.3);
			}
		}

		&--reverse {
			&:hover {
				@media only screen and (min-width: $nohover-width) {
					background: $color-primary;
					color: $white;
					box-shadow: 0 0 0 4px rgba(0, 120, 189, 0.3);
				}
			}
		}

        &--inverse {
            box-shadow: 0 0 0 2px $color-primary;
            color: $color-primary;

            &:hover {
    			@media only screen and (min-width: $nohover-width) {
    				background: $color-primary;
    				color: $white;
    				box-shadow: 0 0 0 4px rgba(0, 120, 189, 0.3);
    			}
    		}
        }

        &.nav__icon {
            background: $color-primary--darken;

            &:hover {
                @media only screen and (min-width: $nohover-width) {
                    background: $white;

                    .nav__icon__bar,
                    .nav__icon__bar:before,
                    .nav__icon__bar:after {
                        background: $color-primary;
                    }

                    &.active {
                        .nav__icon__bar {
                            background: transparent;
                        }
                    }
                }
            }
        }
	}
}

// Animations

// Trigger
.adisq-icon-anim-rfl .adisq-icon {
	&:hover:before {
		@media only screen and (min-width: $nohover-width) {
			animation: toRightFromLeft 0.3s forwards;
		}
	}
}
@keyframes toRightFromLeft {
	49% {
		transform: translate(100%);
	}
	50% {
		@include opacity(0);
		transform: translate(-100%);
	}
	51% {
		@include opacity(1);
	}
}


// Trigger
.adisq-icon-anim-lfr .adisq-icon {
	&:hover:before {
		@media only screen and (min-width: $nohover-width) {
			animation: toLeftFromRight 0.3s forwards;
		}
	}
}
@keyframes toLeftFromRight {
	49% {
		transform: translate(-100%);
	}
	50% {
		opacity: 0;
		transform: translate(100%);
	}
	51% {
		opacity: 1;
	}
}


// Trigger
.adisq-icon-anim-tfb .adisq-icon {
	&:hover:before {
		@media only screen and (min-width: $nohover-width) {
			animation: toTopFromBottom 0.3s forwards;
		}
	}
}
@keyframes toTopFromBottom {
	49% {
		transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}


// Trigger
.adisq-icon-anim-bft .adisq-icon {
	&:hover:before {
		@media only screen and (min-width: $nohover-width) {
			animation: toBottomFromTop 0.3s forwards;
		}
	}

    &:hover .nav__icon__bar {
        @media only screen and (min-width: $nohover-width) {
			animation: NavtoBottomFromTop 0.3s forwards;
		}
    }
}
@keyframes toBottomFromTop {
	49% {
		transform: translateY(100%);
	}
	50% {
		opacity: 0;
		transform: translateY(-100%);
	}
	51% {
		opacity: 1;
	}
}
@keyframes NavtoBottomFromTop {
	49% {
		transform: translateY(600%);
	}
	50% {
		opacity: 0;
		transform: translateY(-600%);
	}
	51% {
		opacity: 1;
	}
}
